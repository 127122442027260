<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.atestado"
                @input="(checked) => onSelect('atestado', checked)"
                :disabled="!canEdit"
              />
              <div>Atestado</div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="form.fields.atestado">
          <b-col cols="3">
            <div class="form-group">
              <label for="cpfPaciente">CPF</label>
              <validation-provider
                mode="lazy"
                name="CPF"
                rules="cpf"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="cpfPaciente"
                  type="text"
                  v-model="form.fields.cpfPaciente"
                  @change="validateCPF(form.fields.cpfPaciente)"
                  class="form-control"
                  :class="{ error: (touched && errors.length > 0) || (existCpf && existCpf.id) }"
                  placeholder="000.000.000-00"
                  v-mask="'###.###.###-##'"
                  :readonly="!canEdit || !canEditCpf"
                  @input="el => updateForm('cpfPaciente', el.target.value)"
                  @blur="updateMedicalRecord(form) && isValidForm() "
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                  >
                <span
                  class="error-message"
                  v-if="existCpf && existCpf.id"
                  >CPF já cadastrado.</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label for="diasAtestado">Período</label>
              <validation-provider
                  name="tempo_de_uso"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
              <multiselect
                    @search-change="changeTempoDeUso($event)"
                    v-model="form.fields.dias"
                    id="diasAtestado"
                    :options="optionTempoDeUso"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    :disabled="!canEdit"
                    placeholder="Descrever"
                    @select="option => onSelect('dias', option)"
                    @blur="updateMedicalRecord(form)"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option }}
                    </template>
                    <template slot="noOptions">
                      <div>Digite para pesquisar...</div>
                    </template>
                    <template slot="noResult">
                      <div>Nenhuma informação encontrada...</div>
                    </template>
                  </multiselect>
                </validation-provider>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="afastamento">Afastamento</label>
              <multiselect
                    :value="form.fields.afastamento"
                    id="afastamento"
                    ref="afastamento"
                    :options="optionAfastamento"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :close-on-select="false"
                    :multiple="true"
                    :disabled="!canEdit"
                    placeholder="Descrever"
                    @select="option => onSelect('afastamento', option, true)"
                    @remove="option => onRemove('afastamento', option)"
                    @blur="updateMedicalRecord(form)"
                    class="with-border multiple"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="selection" slot-scope="{ values }">
                      <div class="treatment">
                        {{ values.join(', ') }}
                      </div>
                    </template>
                    <template slot="noOptions">
                      <div>Digite para pesquisar...</div>
                    </template>
                    <template slot="noResult">
                      <div>Nenhuma informação encontrada...</div>
                    </template>
                  </multiselect>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="cidAtestado">CID</label>
              <multiselect
                :value="form.fields.cid"
                id="cidAtestado"
                :options="cids.map(el => el.cid)"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="false"
                placeholder="Selecionar"
                :disabled="!canEdit"
                class="with-border"
                @select="option => onSelect('cid', option)"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                    {{ option }}
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="form.fields.atestado">
          <b-col cols="10">
            <div class="form-group">
              <v-text-area
                id="maisInformacoes"
                :value="form.fields.maisInformacoes"
                @blur="updateMedicalRecord(form)"
                @inputTextArea="el => updateForm('maisInformacoes', el)"
                :readonly="!canEdit"
                rows="1"
                placeholder="Mais informações"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.declaracaoDeComparecimento"
                @input="(checked) => onSelect('declaracaoDeComparecimento', checked)"
                :disabled="!canEdit"
              />
              <div>Declaração de Comparecimento</div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="auto">
            <div class="form-group">
              <label for="horario">Horário de chegada</label>
              <input
                autocomplete="off"
                :value="form.fields.horarioChegada"
                :readonly="!canEdit || !form.fields.declaracaoDeComparecimento"
                @input="el => updateForm('horarioChegada', el.target.value)"
                @blur="updateMedicalRecord(form)"
                id="horario"
                type="text"
                class="form-control"
                placeholder="00:00"
                v-mask="'##:##'"
              />
            </div>
          </b-col>
          <b-col cols="auto">
            <div class="form-group">
              <label for="horario">Horário de saída</label>
              <input
                autocomplete="off"
                :value="form.fields.horarioSaida"
                id="horario"
                type="text"
                class="form-control"
                :readonly="!canEdit || !form.fields.declaracaoDeComparecimento"
                placeholder="Descrever"
                v-mask="'##:##'"
                @input="el => updateForm('horarioSaida', el.target.value)"
                @blur="updateMedicalRecord(form)"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.declaracaoDeComparecimentoAcompanhante"
                @input="(checked) => onSelect('declaracaoDeComparecimentoAcompanhante', checked)"
                :disabled="!canEdit"
              />
              <div>Declaração de Comparecimento de Acompanhante</div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="form.fields.declaracaoDeComparecimentoAcompanhante">
          <b-col cols="auto">
            <div class="form-group">
              <label for="nomeAcompanhante">Nome do acompanhante</label>
              <input
                autocomplete="off"
                :value="form.fields.nomeAcompanhante"
                id="nomeAcompanhante"
                type="text"
                class="form-control"
                :readonly="!canEdit"
                @input="el => updateForm('nomeAcompanhante', el.target.value)"
                @blur="updateMedicalRecord(form)"
              />
            </div>
          </b-col>
          <b-col cols="auto">
            <div class="form-group">
              <label for="cpfAcompanhante">CPF</label>
              <validation-provider
                mode="lazy"
                name="CPF"
                rules="cpf"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="cpfAcompanhante"
                  type="text"
                  :value="form.fields.cpfAcompanhante"
                  class="form-control"
                  placeholder="000.000.000-00"
                  v-mask="'###.###.###-##'"
                  :readonly="!canEdit"
                  @input="el => updateForm('cpfAcompanhante', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  :class="{ error: (touched && errors.length > 0) }"
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Check from '@/components/General/Check'
import TextArea from '@/components/General/TextArea'

export default {
  components: { FormWrapper, Check, ChevronDown, 'v-text-area': TextArea, },
  created() {
    setTimeout(() => {
      this.updateForm('horarioChegada', this.moment().format('HH:mm'))
      this.updateForm('horarioSaida', null)
      }, 100)
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.atestadosMedicos,
      cids: state => state.attendance.form.cid.value,
      attendance: state => state.attendance.attendance
    })
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      canEditCpf: true,
      validate: false,
      optionTempoDeUso: [],
      optionAfastamento: ['Curso','Escola','Faculdade','Trabalho'],
      touched: false,
      existCpf: false,
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/atestadosMedicos', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    onSelect(key, value, multi) {
      if (multi) {
        const option = value
        value = this.form.fields[key] || []
        value.push(option)
      }
      this.updateForm(key, value)
      this.updateMedicalRecord(this.form)
    },
    onRemove(key, option) {
      const value = this.form.fields[key]
      const index = value.indexOf(option)
      value.splice(index, 1)
      this.updateForm(key, value)
      this.updateMedicalRecord(this.form)
    },
    isValidForm() {
      this.validate = true
      return Boolean(this.form.fields.cpfPaciente)
    },
    validateCPF(cpf) {
      this.api
      .validateCpf(this.clinic.id, cpf)
      .then(res => {
        this.existCpf = cpf.length && res.data

      })
      .catch(error => {
          this.$toast.error(error.message)
      })
    },
    changeTempoDeUso(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push('1 dia', '1 semana', '1 mês', '1 ano')
        } else {
          options.push(
            `${treatedText} dias`,
            `${treatedText} semanas`,
            `${treatedText} meses`,
            `${treatedText} anos`
          )
        }
      } else {
        options.push('1 dia', '1 semana', '1 mês', '1 ano')
      }
      options.push('Manhã')
      options.push('Tarde')
      options.push('Noite')
      !options.includes(query) && query && options.push(query);
      this.optionTempoDeUso = [...options]
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  },
  watch: {
    attendance: {
      handler() {
        if(this.attendance?.patient?.cpf && !this.form.fields.cpfPaciente){
          this.updateForm("cpfPaciente", this.attendance.patient.cpf)
          this.canEditCpf = false
        }
      },
      deep: true,
    }
  },
}
</script>
<style lang="scss" scoped>
.treatment {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

     @media (min-width: 900px) {
      max-width: 15ch;
    }

    @media (min-width: 1200px) {
      max-width: 20ch;
    }
    @media (min-width: 1400px) {
      max-width: 25ch;
    }
    @media (min-width: 1600px) {
      max-width: 30ch;
    }
  }
</style>