<template>
  <Icon :tooltip="tooltip">
    <label class="checkbox">
      <input
        type="checkbox"
        v-model="checked"
        :disabled="Boolean(disabled)"
        :checked="Boolean(checked)"
        @click="onChange(!checked)"
      />
      <span :class="{ checkmark: true, disabled: !!disabled }">
        <Check />
      </span>
      {{ label }}
    </label>
  </Icon>
</template>
<script>
import Icon from '@/components/General/Icon'
import Check from '@/assets/icons/check.svg'
export default {
  components: {
    Icon,
    Check,
  },
  props: {
    id: String,
    value: [Boolean, Number],
    label: String,
    disabled: Boolean,
    tooltip: String,
  },
  data() {
    return {
      checked: this.value,
    }
  },
  methods: {
    onChange(checked) {
      this.$emit('input', checked)
      this.$emit('change', checked)
    }
  },
  watch: {
    value(val) {
      this.checked = val
    }
  }
}
</script>
<style lang="scss" scoped>
  .checkbox {
    top: 1px;  
    padding-left: 28px;  
    margin: 0 0 25px 0;
  }
  .disabled {
    background-color: #D9DFF2 !important;
  }
</style>
